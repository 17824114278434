import styled from '@emotion/styled'
import { MEDIA_QUERIES } from 'constants/ThemeConstant'

export const GlobalStyle = styled.div`
    @media ${MEDIA_QUERIES.MOBILE} {
        .mobFixPosRemove {
            position: static !important;
        }
        .respoTblScrlHndl.ant-table-wrapper{
            overflow-x: hidden !important;
        }
    }
    .werehouseTypeClient,
    .stateSelect,
    .werehouseTypeClient .ant-select-selection-overflow{
        min-height: 36px;
    }
    .fileArrSpace{
        .ant-space-item:first-child,
        .ant-space-item:nth-child(2){
            flex: 1 !important;
        }
    }
    .werehouseTypeClient .ant-select-selection-item {
        margin: 4px;
    }
    .barcodeText {
        transition: width 0.3s ease;
        width: 100px
    }

    .barcodeText:focus {
        width: 200px;
    }
    [data-testid="print__button"],
    [data-testid="get-file__download-button"],
    [data-testid="print__menu"],
    [data-testid="get-file__download-menu"] {
        display: none !important;
    }
`